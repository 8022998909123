<script>
import ContentForm from "@/views/content/contentForm";
import Component, { mixins } from "vue-class-component";
import { toastService } from "@/services/toastService.js";

@Component({
  inject: {
    contentService: "contentService",
    fileUploadService: "fileUploadService",
    visitorLevelService: "visitorLevelService"
  },
})
export default class ContentNew extends mixins(ContentForm) {
  formId = "new-content-form";

  get fieldColumns() {
    return 2;
  }
  get actions() {
    return [
      {
        id: "save",
        type: "submit",
        text: this.translations.labels.button_save,
        leftIcon: "mdi-content-save-outline",
        disabled: () => {
          return this.fileUploadService.isUploading();
        },
        handle: this.save,
      },
    ];
  }
  async save(action, passesValidation) {
    this.errors = [];
    if (!passesValidation) {
      return;
    }
    try {
      const idParent = this.model.idParent;
      const type = this.model.type;
        await this.contentService.create(this.model);
      this.$router.push(`/content/list/${idParent}/${type}`);
      toastService.success(this.translations.success.content_create);
    } catch (error) {
      this.errors = this.formatApiErrors(error);
    }
  }

  getFormTitle() {
    return this.translations.pageTitles.contents_new;
  }

  async created() {
    this.setVisitorLevels(await this.visitorLevelService.enabledLevels(this.model.id));
    this.model = {
      type: this.$route.params.type,
      idParent: this.$route.params.idParent,
      idLanguage: null,
      name: null,
      description: null,
      uri: null,
      previewImageUri: null,
      order: null,
      visitorLevels: this.defaultVisitorLevelsValue()
    };
    this.isReady = true;
  }
}
</script>
